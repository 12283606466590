import React from "react";
import { Route, Routes } from "react-router-dom";
import SiteMapping from "../pages/SiteMapping.js";
import Categories from "../pages/Categories.js";
import Search from "../pages/Search.js";

function DashboardRoute(props) {
  return (
    <Routes>
      <Route path="/" element={<Search />} />
      <Route path="mapping" element={<SiteMapping />} />
      <Route path="categories" element={<Categories />} />
    </Routes>
  );
}

export default DashboardRoute;
