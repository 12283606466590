import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./pages/login/Login.js";
import Dashboard from "./pages/dashboard/Dashboard.js";

function AppRoute(props) {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/dashboard/*" element={<Dashboard />} />
      </Routes>
    </BrowserRouter>
  );
}

export default AppRoute;
