import React, { useState } from "react";
import { IconAdjustments } from "@tabler/icons";
import _ from "lodash";
import ChartItem from "../components/ChartItem.js";
import axios from "axios";
import Grid2 from "@mui/material/Unstable_Grid2";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  TextField,
} from "@mui/material";

function Search(props) {
  const [keyword, setKeyword] = useState("");
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [col, setCol] = useState(6);
  const [showFilter, setShowFilter] = useState(false);

  const toggleFilter = () => {
    setShowFilter(!showFilter);
  };

  const search = () => {
    if (!keyword.trim().length) return;

    setLoading(true);
    axios
      .get(`https://crosswalk.services/search.php?keyword=${keyword}`)
      .then((result) => {
        if (result.data.length) {
          setData(_.groupBy(result.data, "key"));
        } else {
          setData({});
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <React.Fragment>
      <Grid2 container>
        <Grid2 xsOffset={3} item xs={4}>
          <TextField
            value={keyword}
            fullWidth
            size="large"
            variant="standard"
            onKeyDown={(e) => {
              if (e.key === "Enter") search();
            }}
            onChange={(e) => setKeyword(e.currentTarget.value)}
            placeholder="Enter word to search"
            inputWrapperOrder={["label", "error", "input", "description"]}
          />
        </Grid2>
        {/*<Grid item xs={1} />*/}
        <Grid2 item xs={1}>
          <Button fullWidth variant="contained" onClick={search} sx={{ ml: 2 }}>
            {" "}
            Search{" "}
          </Button>
        </Grid2>
        <Grid2 item xs={1}>
          <Button
            variant="outlined"
            onClick={toggleFilter}
            fullWidth
            sx={{ ml: 4 }}
          >
            <IconAdjustments />
          </Button>
        </Grid2>
      </Grid2>
      <br />
      <br />
      {loading && <div>{<CircularProgress />}</div>}
      {!loading && Object.keys(data).length > 0 && (
        <Grid2 container>
          <Grid2 item xs={col}>
            <ChartItem label="Age" category="age" data={data} />
          </Grid2>
          <Grid2 item xs={col}>
            <ChartItem
              label="Children Count"
              category="childrenCount"
              data={data}
            />
          </Grid2>
          <Grid2 item xs={col}>
            <ChartItem label="City" category="city" data={data} />
          </Grid2>
          <Grid2 item xs={col}>
            <ChartItem label="Education" category="education" data={data} />
          </Grid2>
          <Grid2 item xs={col}>
            <ChartItem label="Ethnicity" category="ethnicity" data={data} />
          </Grid2>
          <Grid2 item xs={col}>
            <ChartItem label="Gender" category="gender" data={data} />
          </Grid2>
          <Grid2 item xs={col}>
            <ChartItem label="Income" category="income" data={data} />
          </Grid2>
          <Grid2 item xs={col}>
            <ChartItem label="Occupation" category="occupation" data={data} />
          </Grid2>
          <Grid2 item xs={col}>
            <ChartItem
              label="Parental Status"
              category="parentalStatus"
              data={data}
            />
          </Grid2>
          <Grid2 item xs={col}>
            <ChartItem
              label="Primary Language"
              category="primaryLanguage"
              data={data}
            />
          </Grid2>
          <Grid2 item xs={col}>
            <ChartItem
              label="Relationship"
              category="relationship"
              data={data}
            />
          </Grid2>
          <Grid2 item xs={col}>
            <ChartItem
              label="Sexual Orientation"
              category="sexualOrientation"
              data={data}
            />
          </Grid2>
          <Grid2 item xs={col}>
            <ChartItem label="State" category="state" data={data} />
          </Grid2>
        </Grid2>
      )}

      <Dialog open={showFilter} onClose={toggleFilter}>
        <DialogTitle>
          <span>Filter</span>
        </DialogTitle>
        <DialogContent>
          <div>
            <strong>Gender</strong>
          </div>
          <div>
            <FormControlLabel control={<Checkbox />} label="Male" />
            <FormControlLabel control={<Checkbox />} label="Female" />
            <FormControlLabel control={<Checkbox />} label="Trans Male" />
            <FormControlLabel control={<Checkbox />} label="Trans Female" />
            <FormControlLabel control={<Checkbox />} label="Non Binary" />
          </div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus>Cancel</Button>
          <Button variant="contained">Apply</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default Search;
