import React from "react";
import { Link } from "react-router-dom";
import { AppBar, Toolbar, Typography } from "@mui/material";

const headerNav = [
  {
    label: "Search",
    href: "/",
  },
  {
    label: "Mapping",
    href: "/mapping",
  },
  {
    label: "Categories",
    href: "/categories",
  },
];

function Header(props) {
  const getMenuButtons = () => {
    return headerNav.map(({ label, href }) => {
      return (
        <Link className="top-menu txt-white" to={`/dashboard${href}`}>
          {label}
        </Link>
      );
    });
  };

  return (
    <header>
      <AppBar component="nav" position="static">
        <Toolbar>
          <Typography variant="h6" className="menu-logo">
            <Link className="txt-white" to="/dashboard">
              Crosswalk
            </Link>
          </Typography>
          <div>{getMenuButtons()}</div>
        </Toolbar>
      </AppBar>
    </header>
  );
}

export default Header;
