import React, { useState } from "react";
import "./login.css";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardContent,
  TextField,
  Typography,
} from "@mui/material";

function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const onSubmit = () => {
    if (username === "admin" && password === "admin") {
      navigate("/dashboard");
    }
  };

  return (
    <Card id="login-form">
      <CardContent>
        <Typography variant="h5">Welcome to Analytics </Typography>
        <br />
        <form onSubmit={onSubmit}>
          <div>
            <TextField
              fullWidth
              required
              variant="standard"
              size="medium"
              label="Email"
              placeholder="hello@example.com"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <TextField
              sx={{ mt: 4 }}
              fullWidth
              required
              size="medium"
              variant="standard"
              type="password"
              label="Password"
              placeholder="Your password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div>
            <Button
              size="medium"
              sx={{ mt: 4 }}
              variant="contained"
              fullWidth
              type="submit"
              onClick={onSubmit}
            >
              Login
            </Button>
          </div>
        </form>
      </CardContent>
    </Card>
  );
}

export default Login;
