import React, { useState } from "react";
import Chart from "react-apexcharts";
import { Card, CardContent, Paper } from "@mui/material";

function ChartItem({ label, category, data }) {
  // console.log(data, category, data[category]);
  const [options, setOptions] = useState({
    chart: {
      id: "demographics",
    },
    xaxis: {
      categories: data[category].map((item) => item.label),
    },
    yaxis: {
      labels: {
        show: false,
        formatter: function (val) {
          return val;
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val;
      },
    },
  });
  const [series, setSeries] = useState([
    {
      name: "Count",
      data: data[category].map((item) => item.count),
    },
  ]);

  return (
    <Card sx={{ mb: 3, mr: 3 }}>
      <CardContent>
        <div>{label}</div>
        <br />
        <div>
          <Chart
            options={options}
            series={series}
            type="bar"
            width={"100%"}
            height={200}
          />
        </div>
      </CardContent>
    </Card>
  );
}

export default ChartItem;
