import { useEffect, useState } from "react";
import axios from "axios";
import MaterialTable from "@material-table/core";

function Categories(props) {
  const [data, setData] = useState([]);
  const columns = [
    { title: "ID", field: "id", editable: "never" },
    { title: "Name", field: "name" },
  ];

  useEffect(() => {
    fetchAll();
  }, []);

  const fetchAll = () => {
    axios
      .get("https://crosswalk.services/category-get.php")
      .then((res) => {
        setData(res.data);
      })
      .catch((error) => {});
  };

  const handleRowUpdate = (rowData) => {
    if (!rowData.name) return Promise.reject();
    return axios
      .post("https://crosswalk.services/category-post.php", {
        type: "UPDATE",
        name: rowData.name,
        id: rowData.id,
      })
      .then(fetchAll);
  };

  const handleRowAdd = (rowData) => {
    if (!rowData.name) return Promise.reject();
    return axios
      .post("https://crosswalk.services/category-post.php", {
        type: "NEW",
        name: rowData.name,
      })
      .then(fetchAll);
  };

  const handleRowDelete = (rowData) => {
    return axios
      .post("https://crosswalk.services/category-post.php", {
        type: "DELETE",
        id: rowData.id,
      })
      .then(fetchAll);
  };

  return (
    <MaterialTable
      title="Category"
      columns={columns}
      data={data}
      options={{
        paging: true,
        pageSize: 15,
        emptyRowsWhenPaging: false,
        pageSizeOptions: [15, 30, 45],
      }}
      editable={{
        onRowUpdate: handleRowUpdate,
        onRowAdd: handleRowAdd,
        onRowDelete: handleRowDelete,
      }}
    />
  );
}

export default Categories;
