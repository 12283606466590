import React from "react";
import Header from "../../components/Header.js";
import DashboardRoute from "../../components/DashboardRoute.js";
import { Container } from "@mui/material";

function Dashboard(props) {
  return (
    <React.Fragment>
      <Header />
      <Container>
        <br />
        <br />
        <DashboardRoute />
        <br />
        <br />
      </Container>
    </React.Fragment>
  );
}

export default Dashboard;
