import axios from "axios";
import { useEffect, useState } from "react";
import MaterialTable from "@material-table/core";

const cols = [
  { title: "ID", field: "id", editable: "never" },
  { title: "Domain", field: "domain" },
  { title: "Category", field: "category_id" },
];

function SiteMapping(props) {
  const [columns, setColumns] = useState(cols);
  const [data, setData] = useState([]);
  const [category, setCategory] = useState([]);

  useEffect(() => {
    fetchCategory();
  }, []);

  useEffect(() => {
    if (category.length) {
      fetchAll();
    }
  }, [category]);

  const fetchCategory = () => {
    return axios
      .get("https://crosswalk.services/category-get.php")
      .then((res) => {
        const obj = {};
        res.data.forEach((element) => {
          obj[element.id] = element.name;
        });
        const tmp = [...cols];
        tmp.find((item) => item.field === "category_id").lookup = obj;
        setColumns(tmp);
        setCategory(res.data);
      })
      .catch((error) => {});
  };

  const fetchAll = () => {
    axios
      .get("https://crosswalk.services/mapping-get.php")
      .then((res) => {
        const tmp = res.data.map((m) => {
          const cat = category.find((c) => c.id === m.category_id);
          return { ...m, name: cat.name };
        });
        setData(tmp);
      })
      .catch((error) => {});
  };
  const handleRowUpdate = (rowData) => {
    if (!rowData.domain || !rowData.category_id) return Promise.reject();
    return axios
      .post("https://crosswalk.services/mapping-post.php", {
        type: "UPDATE",
        domain: rowData.domain,
        category_id: rowData.category_id,
        id: rowData.id,
      })
      .then(fetchAll);
  };

  const handleRowAdd = (rowData) => {
    if (!rowData.domain || !rowData.category_id) return Promise.reject();
    return axios
      .post("https://crosswalk.services/mapping-post.php", {
        type: "NEW",
        domain: rowData.domain,
        category_id: rowData.category_id,
      })
      .then(fetchAll);
  };

  const handleRowDelete = (rowData) => {
    console.log(rowData);
    return axios
      .post("https://crosswalk.services/mapping-post.php", {
        type: "DELETE",
        id: rowData.id,
      })
      .then(fetchAll);
  };

  return (
    <MaterialTable
      title="Domain Category Mapping"
      columns={columns}
      data={data}
      options={{
        paging: true,
        pageSize: 15,
        emptyRowsWhenPaging: false,
        pageSizeOptions: [15, 30, 45],
      }}
      editable={{
        onRowUpdate: handleRowUpdate,
        onRowAdd: handleRowAdd,
        onRowDelete: handleRowDelete,
      }}
    />
  );
}

export default SiteMapping;
